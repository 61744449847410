@import 'mantine';
.helpSupportBlock {
  background-color: var(--mantine-color-blue-5);
  color: var(--mantine-color-gray-0);
  text-align: center;
  padding-top: rem(120);
  padding-bottom: rem(120);
  overflow: hidden;

  @include larger-than($mantine-breakpoint-desktop-sm) {
    text-align: start;
  }

  h2 {
    @include h1-mobile;

    @include larger-than($mantine-breakpoint-desktop-sm) {
      @include h1-desktop;
    }
  }
}

.text {
  z-index: 1;

  p {
    margin: 0;
  }
}

.buttonsWrapper {
  margin-top: rem(64);
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: rem(10);

  @include larger-than($mantine-breakpoint-desktop-sm) {
    margin-top: initial;
    align-items: start;
  }

  > * {
    z-index: 1;
  }
}

.lines {
  position: absolute;
  top: rem(-80);
  left: rem(60);
  z-index: 0;

  @include larger-than($mantine-breakpoint-desktop-sm) {
    top: rem(-140);
    left: rem(30);
  }
}
